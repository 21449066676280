import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { Router, RouterLink } from '@angular/router';
import { TodaysOrderComponent } from '../../dashboard/ecommerce/website-overview/todays-order/todays-order.component';
import { TodaysRevenueComponent } from '../../dashboard/ecommerce/website-overview/todays-revenue/todays-revenue.component';
import { AverageValueComponent } from '../../dashboard/ecommerce/website-overview/average-value/average-value.component';
import { AllSpendingComponent } from '../../dashboard/ecommerce/website-overview/all-spending/all-spending.component';
import { ExpectedEarningsComponent } from '../../dashboard/ecommerce/website-overview/expected-earnings/expected-earnings.component';
import { WeekReportComponent } from '../../dashboard/ecommerce/website-overview/week-report/week-report.component';
import { BasicTableComponent } from '../../ui-elements/table/basic-table/basic-table.component';
import { CompaniesTableComponent } from './companies-table/companies-table.component';
import { UserService } from '../services/user.service';
import { AppStateService } from '../state/app-state.service';
import { fetchAuthSession } from 'aws-amplify/auth';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink, MatCardModule, TodaysOrderComponent, TodaysRevenueComponent, AverageValueComponent, AllSpendingComponent, ExpectedEarningsComponent, WeekReportComponent, BasicTableComponent, HomeComponent, CompaniesTableComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  constructor(
    private _router: Router,
    private _userService: UserService,
    private _stateService: AppStateService,
  ) {
    this.setAppState();
  }
  
  async setAppState() {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    const idTokenPayload: any = idToken!.payload!
    const organizations  = idTokenPayload["cognito:groups"];
    const email = idTokenPayload["email"]?.toString();

    if(organizations.length > 1) {
      this._stateService.setState(email, "", organizations, []);
      this._router.navigate(['/select-organization'])
    } else {
      const organization  = idTokenPayload["cognito:groups"][0];
      this._userService.getUserAccess(organization, email).subscribe((res) => {
        const accesos = res.availableAccess;
        this._stateService.setState(email, organization, organizations, accesos);
        this._router.navigate(['/tests']) 
      })
    }
  }
}
